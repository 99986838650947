<template>
  <div id="env-pm-view">
    <div class="spot-key">
      <span>{{ $route.params.key }}</span>
    </div>
    <div class="content-box">
      <div class="map-list">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
      </div>
      <div class="title">作業環境監測</div>
      <table class="table">
        <thead>
          <th>
            {{
              airPM.machine_name.substring(airPM.machine_name.indexOf('_') + 1)
            }}
            點
          </th>
          <th>數值</th>
          <th>告知事項</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="pm"
                >PM<span class="small">2.5</span>(μg/m<span class="small top"
                  >3</span
                ><span class="right-brackets">)</span></span
              >
            </td>
            <td
              :class="
                setClass(
                  spotInfo.attributes.find((e) => e.key === 'PM2.5').value
                )
              "
            >
              <span>
                {{ spotInfo.attributes.find((e) => e.key === 'PM2.5').value }}
              </span>
            </td>
            <td>
              <div class="item">
                <img
                  :src="
                    require('../assets/' +
                      setUrl(
                        spotInfo.attributes.find((e) => e.key === 'PM2.5').value
                      ) +
                      '-face.png')
                  "
                />
                <span>
                  {{
                    setMemo(
                      spotInfo.attributes.find((e) => e.key === 'PM2.5').value
                    )
                  }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="level">
        <div class="item">
          <img src="../assets/happy-face.png" />
          <span class="positive level-name">低級：</span>
          <span>建議可正常活動</span>
        </div>
        <div class="item">
          <img src="../assets/soso-face.png" />
          <span class="medium level-name">中級：</span>
          <span>建議敏感性族群減少戶外活動</span>
        </div>
        <div class="item">
          <img src="../assets/bad-face.png" />
          <span class="negative level-name">高級：</span>
          <span>建議一般民眾應考慮減少戶外活動</span>
        </div>
      </div>
    </div>
    <div class="logo-group">
      <!-- <img src="~@/assets/futsu-logo.png" id="futsu-logo" /> -->
      <!-- <img src="~@/assets/lin-logo.png" id="lin-logo" /> -->
      <!-- <img src="~@/assets/cec-logo.png" id="cec-logo" /> -->
    </div>
  </div>
</template>

<script>
import { getAir02 } from '@/api/index.js'
import { mapState } from 'vuex'
export default {
  name: 'Env',
  mixins: [],
  data() {
    return {
      dotUrl: '',
      airPM: null,
      airStandardMed: 35,
      airStandardHigh: 53,
      spotInfo: null,
    }
  },
  async created() {
    this.dotUrl = require('../assets/' + this.$route.params.key + '-dot.png')
    const id = parseInt(this.$route.params.id)
    this.airPM = this.machineInfo.find(
      (e) =>
        e.machine_group === this.$route.params.key.toUpperCase() &&
        e.machine_type === 'air_PM2.5' &&
        e.id === id
    )
    const aSpotId = this.airPM.machine_url.substring(
      this.airPM.machine_url.indexOf('?id=') + 4
    )
    getAir02(aSpotId)
      .then((res) => {
        this.spotInfo = res.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapState(['isLogin', 'stationInfoList', 'machineInfo']),
    loading() {
      return false
    },
  },
  methods: {
    setUrl(value) {
      if (value < this.airStandardMed) {
        return 'happy'
      } else if (value < this.airStandardHigh) {
        return 'soso'
      } else {
        return 'bad'
      }
    },
    setMemo(value) {
      if (value < this.airStandardLow) {
        return '低級'
      } else if (value < this.airStandardHigh) {
        return '中級'
      } else {
        return '高級'
      }
    },
    setClass(value) {
      if (value > this.airStandardLow) {
        return 'negative'
      } else {
        return 'positive'
      }
    },
    goCctvList() {
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
}
</script>

<style></style>
